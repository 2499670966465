@import '../../../../assets/style/main';
.hotspotMarker {
  position: absolute;
  pointer-events: visible;
  transform: translate(-50%, -50%);

  .copyPostcard {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(100%, -50%);
  }

  &:not(.darkPin):not(.postcardPin) {
    &::after {
      content: '';
      border-radius: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid rgba(white, 0.8);
      animation: pulseCircle 1.3s ease infinite;
      pointer-events: none;

      mask-size: 200px auto;
      -webkit-mask-image: url('../../../../assets/images/grunge.png');
      -o-mask-image: url('../../../../assets/images/grunge.png');
      -moz-mask-image: url('../../../../assets/images/grunge.png');
      -ms-mask-image: url('../../../../assets/images/grunge.png');
      mask-image: url('../../../../assets/images/grunge.png');
    }

    &::before {
      content: '';
      width: 13px;
      height: 8px;
      background: url('../../../../assets/images/map/arrow_down.png') no-repeat;
      background-size: 100% auto;
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .mark {
      width: 38px;
      height: 38px;
      border-radius: 19px;

      &.PLANNING {
        background: $colorPrimaryBlue;
      }

      &.DEVELOPING {
        background: $colorPrimaryTurquoise;
      }

      &.DEVELOPED {
        background: $colorPrimaryMagenta;
      }

      &::before {
        width: 10px;
        height: 10px;
        background: white;
      }
    }
  }

  &.postcardPin {
    &::after {
      content: '';
      //background: url('../../../../assets/images/map/postcard_pin.png') no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90px;
      height: 90px;
      background-size: 100% auto;
      transform: translate(-50%, -50%);
      pointer-events: none;

      mask-size: 100px auto;
      -webkit-mask-image: url('../../../../assets/images/grunge.png');
      -o-mask-image: url('../../../../assets/images/grunge.png');
      -moz-mask-image: url('../../../../assets/images/grunge.png');
      -ms-mask-image: url('../../../../assets/images/grunge.png');
      mask-image: url('../../../../assets/images/grunge.png');
    }

    &::before {
      content: '';
      width: 13px;
      height: 8px;
      background: url('../../../../assets/images/map/arrow_down.png') no-repeat;
      background-size: 100% auto;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .mark {
      width: 90px;
      height: 90px;
    }
  }

  &.darkPin {
    width: 84px;
    height: 137px;

    &::after {
      content: '';
      //background: url('../../../../assets/images/map/dark_pin.png') no-repeat;
      position: absolute;
      top: calc(50% + 12px);
      left: 50%;
      width: 84px;
      height: 97px;
      background-size: 100% auto;
      transform: translate(-50%, -100%);
      pointer-events: none;
    }

    .mark {
      height: 105px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba($colorPrimaryMagenta, 0.45);
        border-radius: 50%;
        animation: pulseDarkCircle 1.3s ease infinite;
      }

      &::before {
        width: 16px;
        height: 16px;
        background: rgba($colorPrimaryMagenta, 0.45);
      }
    }
  }

  .mark {
    display: block;
    mask-size: 1200px auto;

    &::before {
      content: '';
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > button {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

@keyframes pulseCircle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    width: 88px;
    height: 88px;
    opacity: 0;
  }
}

@keyframes pulseDarkCircle {
  0% {
    width: 45px;
    height: 45px;
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    width: 65px;
    height: 65px;
    opacity: 0;
  }
}
