//input, textarea {
//  &:focus {
//    border-color: unset !important;
//  }
//}

.worldhidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none;
}

.w {
  width: 100%;
}

.wh {
  width: 100%;
  height: 100%;
}

.f-cb {
  zoom: 1;
}

.f-cb:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  overflow: hidden;
  content: '.';
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  overflow: hidden;
  content: '.';
}

.text-ul {
  text-decoration: underline;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.text-one {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.tdt {
  text-decoration: line-through;
}

.por {
  position: relative;
}

.poa {
  position: absolute;
}

.pof {
  position: fixed;
}

.ovh {
  overflow: hidden;
}

/*bold  */

.bold {
  font-weight: bold;
}

/* flex */
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-a {
  display: flex;
  justify-content: space-around;
}

.flex-b {
  display: flex;
  justify-content: space-between;
}

.flex-c {
  display: flex;
  justify-content: center;
}

.flex-c-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-v {
  display: flex;
  align-items: center;
}

.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-e {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col-cc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-cs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-b-e {
  display: flex;
  align-items: flex-end;
}

.flex-b-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.flex-sc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-ec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-c-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-aend {
  display: flex;
  align-items: flex-end;
}

.flex-end-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-col-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-col-b {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-col-s {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-ss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-base {
  display: flex;
  align-items: baseline;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.arrow-right {
  position: relative;
}

.arrow-right::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -6px;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 3px solid #c4c4c4;
  border-right: 3px solid #c4c4c4;
  transform: rotate(45deg);
}


::-webkit-scrollbar {
  // display: none;
  /* background-color:transparent; */
}
