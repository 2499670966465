@import './assets/style/main.scss';

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; // this is to avoid tnt footer overlapping
  width: 100%;
  background-color: $colorBlack;
  height: 100%;

  * {
    @include mediaMin768 {
      cursor: none !important;
    }
  }
}
