.landing {
  position: relative;
  width: 100%;
  height: 100%;

  .content {
    position: relative;
    z-index: 6;
    display: flex;
    align-items: center;
    width: 92%;
    height: 100%;
    margin: 0 auto;
    pointer-events: none;
  }

  .pagination {
    position: fixed;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -16px;
    color: $colorWhite;
    opacity: 0;
    pointer-events: all;

    .button {
      padding: 15px 20px;

      > * {
        pointer-events: none;
      }

      .arrow-up, .arrow-down {
        width: 13px;
        height: 56px;
        opacity: 0.5;
      }

      .active {
        opacity: 1
      }

      .arrow-down {
        transform: rotateZ(180deg);
      }
    }

    .current {
      padding: 15px 0;
    }

    .next {
      padding: 15px 0 0;
      opacity: 0.5;

    }

    .line {
      display: block;
      width: 15px;
      height: 1px;
      background-color: $colorWhite;
    }
  }
}
