@import './variables.scss';
// Horizontal bounce
@keyframes horizontal-bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3px);
  }
}

// Vertical bounce
@keyframes vertical-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
}

// Highlight
@keyframes highlight {
  0% {
    filter: drop-shadow(0 0 4px rgba($colorWhite, 0.5));
  }
  100% {
    filter: drop-shadow(0 0 4px rgba($colorWhite, 1));
  }
}

// Rotate reveal
@keyframes rotateReveal {
  0% {
    clip-path: polygon(0 50%, 30% 0, 100% 0, 100% 50%, 100% 50%, 80% 100%, 0 100%);
  }
  49% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(90deg);
    clip-path: polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%);
  }
  51% {
    transform: rotate(90deg);
    clip-path: polygon(0 50%, 30% 0, 30% 0, 0 50%, 0% 50%, 0% 100%, 0 100%);
  }
  75% {
    clip-path: polygon(0 50%, 30% 0, 100% 0, 85% 50%, 0% 50%, 0% 100%, 0 100%);
  }
  100% {
    transform: rotate(90deg);
    clip-path: polygon(0 50%, 30% 0, 100% 0, 100% 50%, 100% 50%, 85% 100%, 0 100%);
  }
}
