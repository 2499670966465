.globalTitle01 {
  font-size: 13vw;
  font-weight: 600;
  line-height: 0.9;

  @include mediaMin768 {
    font-size: 11vw;
  }

  @include mediaMin1024 {
    font-size: 9vw;
  }

  @include mediaMin1240 {
    font-size: 8.5vw;
  }

  @include mediaMin1600 {
    font-size: 8vw;
  }

  @include mediaMin1920 {
    font-size: 160px;
  }
}

.globalTitle02 {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.1;
  letter-spacing: 1px;

  @media (max-width: 374px) {
    font-size: 13vw;
  }

  @include mediaMin768 {
    font-size: 20px;
  }

  @include mediaMin1024 {
    font-size: 30px;
  }

  @include mediaMin1240 {
    font-size: 40px;
  }

  @include mediaMin1600 {
    font-size: 46px;
  }

  @include mediaMin1920 {
    font-size: 48px;
  }
}

.globalTitle03 {
  font-size: 60px;
  font-weight: 700;
}

.globalBody01 {
  font-size: 6vw;
  line-height: 1.15;
  letter-spacing: 1px;

  @include mediaMin480 {
    font-size: 22px;
  }

  @include mediaMin768 {
    font-size: 26px;
  }

  @include mediaMin1024 {
    font-size: 24px;
  }

  @include mediaMin1240 {
    font-size: 30px;
  }

  @include mediaMin1600 {
    font-size: 35px;
  }

  @include mediaMin1920 {
    font-size: 37px;
  }
}

.globalBody03 {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.globalTagline01 {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;

  @include mediaMin1240 {
    font-size: 16px;
  }
}

.globalButton01 {
  font-size: 20px;
  font-weight: 700;
  line-height: 0.9;

  @include mediaMin1024 {
    font-size: 22px;
  }

  @include mediaMin1240 {
    font-size: 24px;
  }
}

.globalButton02 {
  font-size: 16px;
  font-weight: 500;
}
