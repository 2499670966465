@import '../../assets/style/variables';
@import '../../assets/style/util/mixin';
.underlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $colorBlack;
  color: $colorWhite;
  z-index: 10;
}

.loader {
  position: absolute;
  width: 268px;
  height: 268px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('../../assets/images/map/compass.png') no-repeat;
  background-size: 100% auto;
  z-index: 20;

  @include mediaMin768 {
    width: 278px;
    height: 278px;
  }
}

.loadProgress {
  position: absolute;
  width: 185px;
  height: 185px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px dotted $colorWhite;
  border-radius: 50%;
}

.progressRing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .circleDots {
    stroke-dasharray: 3 3;
    clip-path: url(#progress);
  }

  .progress {
    transition: stroke-dashoffset 0.15s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: 0 0;
    stroke-dashoffset: 0;
  }
}

.loadText {
  position: absolute;
  top: calc(50% + 155px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;

  @include device-desktop {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    color: transparent;
  }
}
