@import './util/mixin/index.scss';
@import './base.scss';
@import './animation.scss';
@import './type.scss';
@import './landing.scss';
@import './font.scss';
@import './common';

#root {
  min-width: 1400px;
}

.texture {
  mask-size: 600px auto;
  -webkit-mask-image: url('../../assets/images/grunge.png');
  -o-mask-image: url('../../assets/images/grunge.png');
  -moz-mask-image: url('../../assets/images/grunge.png');
  -ms-mask-image: url('../../assets/images/grunge.png');
  mask-image: url('../../assets/images/grunge.png');
}

.lineScape:nth-child(2) {
  position: relative;
  top: -20px;
}

.lineScape:nth-child(3) {
  position: relative;
  top: -40px;
}

.isNumber {
  font-weight: 500;
}

.font-Regular {
  font-family: Cormorant-Regular, serif;
}

.font-SC {
  font-family: Cormorant-SC, serif;
}

.font-Medium {
  font-family: Cormorant-Medium, serif;;
}
.bscscan{
  pointer-events: all;
  color: #02cdd5;
}