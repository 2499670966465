.customCursor {
  position: absolute;
  z-index: 20;
  transform: translate(-50%, -50%);
  transform-origin: center;
  border: transparent;
  border-radius: 50%;
  color: #fff;
  pointer-events: none;
  transition: width 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
  height 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
  border 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
  opacity 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .background {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background-color 350ms ease-in-out;
    background-color: #de1045;
  }

  &.default {
    height: 20px;
    width: 20px;

    .background {
      background-color: #de1045;
    }

    &.secondary {
      .background {
        background-color: white;
      }
    }
  }

  &.pointer {
    height: 70px;
    width: 70px;
    border: 2px solid #de1045;

    &.secondary {
      border: 2px solid white;
    }

    .background {
      background-color: transparent;
    }
  }

  &.action {
    height: 118px;
    width: 118px;

    .background {
      background-color: #de1045;
    }

    &.secondary {
      .background {
        background-color: white;
      }
    }
  }

  &.actionBig {
    height: 220px;
    width: 220px;

    .background {
      background-color: #de1045 !important;
    }

    &.secondary {
      .background {
        background-color: white;
      }
    }
  }

  &.mask {
    height: 220px;
    width: 220px;

    .background {
      opacity: 0;
    }
  }

  &.maskAlt {
    height: 345px;
    width: 345px;

    .background {
      opacity: 0;
    }
  }

  .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity ease-in-out 150ms;
    text-align: center;
    line-height: 1;

    &.showText {
      opacity: 1;
      transition: opacity ease-in-out 500ms;
    }
  }
}
