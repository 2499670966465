@mixin mediaMax479 {
  @media (max-width: 479px) {
    @content;
  }
}

@mixin mediaMin480 {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin mediaMin768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mediaMin1024 {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mediaMin1240 {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin mediaMin1336 {
  @media (min-width: 1336px) {
    @content;
  }
}
@mixin mediaMin1440 {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin mediaMin1600 {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin mediaMin1920 {
  @media (min-width: 1920px) {
    @content;
  }
}
