.hotspotPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 380px;
  height: 216px;
  z-index: 1;
  color: white;
  perspective: 1000px;

  &.positionYtop {
    &.positionXleft {
      transform: translateY(-100%) translateX(-100%);

      .thumbnailImgWrapper {
        transform-origin: bottom right;
      }
    }

    &.positionXright {
      transform: translateY(-100%) translateX(0);

      .thumbnailImgWrapper {
        transform-origin: bottom left;
      }
    }
  }

  &.positionYbottom {
    &.positionXleft {
      transform: translateY(0) translateX(-100%);

      .thumbnailImgWrapper {
        transform-origin: top right;
      }
    }

    &.positionXright {
      transform: translateY(0) translateX(0);

      .thumbnailImgWrapper {
        transform-origin: top left;
      }
    }
  }
}

.thumbnailTitleWrapper {
  position: absolute;
  top: 20px;
  left: -42px;
  text-align: left;
  pointer-events: none;
  z-index: 2;

  .thumbnailCaption {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: block;
  }

  .thumbnailTitle {
    font-size: 60px;
    font-weight: 500;
    // font-style: italic;
    // max-width: 200px;
    position: relative;
    top: -20px;
    line-height: 70px;
    // opacity: 0;

    i {
      font-style: italic;
    }

    span {
      text-transform: uppercase;
    }
  }
}

.thumbnailImgWrapper {
  position: relative;

  &::after {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(black, 0.4) 0, rgba(black, 0) 100%);
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    content: '';
    width: 340px;
    height: 340px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    background: radial-gradient(rgba(black, 0.4) 0, rgba(black, 0) 50%);
    pointer-events: none;
  }
}

.thumbnailImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}
