
.imageComparer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #e7e5e2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.visible {
      // content
    }

    &.hidden {
      pointer-events: none;
    }
  }

  .hiddenImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: all;

    opacity: 0;

    transition: opacity 200ms ease-in-out;

    div[data-device='desktop'] & {
      &:not(:hover) {
        opacity: 0 !important; // Leave this as it is even if it looks weird, is a trick to avoid a flicker issue
      }
    }
  }
}
