@font-face {
  font-family: 'Cormorant-Regular';
  src: url('../font/Cormorant-Regular.eot');
  src: url('../font/Cormorant-Regular.eot?#font-spider') format('embedded-opentype'),
  url('../font/Cormorant-Regular.woff2') format('woff2'),
  url('../font/Cormorant-Regular.woff') format('woff'),
  url('../font/Cormorant-Regular.ttf') format('truetype'),
  url('../font/Cormorant-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant-SC';
  src: url('../font/Cormorant-Medium.otf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Cormorant-Medium';
  src: url('../font/Cormorant-Medium.otf');
  font-weight: normal;
  font-style: normal;
}
